const options1 = [ // 精品家具 - 产品类型
  {
    id: 1,
    name: '软体两厅',
    checked: false
  },
  {
    id: 2,
    name: '套房家具',
    checked: false
  },
  {
    id: 3,
    name: '儿童家具',
    checked: false
  },
  {
    id: 4,
    name: '睡眠中心',
    checked: false
  },
  {
    id: 5,
    name: '国际品牌',
    checked: false
  },
  {
    id: 6,
    name: '智能家具',
    checked: false
  },
  {
    id: 7,
    name: '全屋定制',
    checked: false
  },
  {
    id: 8,
    name: '高定家具',
    checked: false
  },
  {
    id: 9,
    name: '办公家具',
    checked: false
  },
  {
    id: 10,
    name: '其它',
    checked: false
  },
];
const options4 = [ // 软装饰品 - 产品类型
  {
    id: 25,
    name: '日用摆件',
    checked: false
  },
  {
    id: 26,
    name: '整合软装',
    checked: false
  },
  {
    id: 27,
    name: '布艺软装',
    checked: false
  },
  {
    id: 28,
    name: '艺术装置',
    checked: false
  },
  {
    id: 29,
    name: '装饰画',
    checked: false
  },
  {
    id: 30,
    name: '地毯',
    checked: false
  },
  {
    id: 31,
    name: '艺术衍生品',
    checked: false
  },
  {
    id: 32,
    name: '其它',
    checked: false
  }
];
const options5 = [ // 建材卫浴 - 产品类型
  {
    id: 33,
    name: '卫浴类',
    checked: false
  },
  {
    id: 34,
    name: '板材类',
    checked: false
  },
  {
    id: 35,
    name: '涂料类',
    checked: false
  },
  {
    id: 36,
    name: '陶瓷类',
    checked: false
  },
  {
    id: 37,
    name: '全屋定制类',
    checked: false
  },
  {
    id: 38,
    name: '五金类',
    checked: false
  },
  {
    id: 39,
    name: '厨电类',
    checked: false
  },
  {
    id: 40,
    name: '吊顶类',
    checked: false
  },
  {
    id: 41,
    name: '装配式类',
    checked: false
  },
  {
    id: 42,
    name: '辅材类',
    checked: false
  },
  {
    id: 43,
    name: '门窗类',
    checked: false
  },
  {
    id: 44,
    name: '其它',
    checked: false
  }
];
const options6 = [ // 时尚灯饰 - 产品类型
  {
    id: 45,
    name: '室内装饰灯具',
    checked: false
  },
  {
    id: 46,
    name: '定制灯饰',
    checked: false
  },
  {
    id: 47,
    name: '商业照明',
    checked: false
  },
  {
    id: 48,
    name: '智能照明',
    checked: false
  },
  {
    id: 49,
    name: '其它',
    checked: false
  }
];
const options8 = [ // 设计材料 - 产品类型
  {
    id: 56,
    name: '设计面料类',
    checked: false
  },
  {
    id: 57,
    name: '家具木材',
    checked: false
  },
  {
    id: 58,
    name: '定制板材',
    checked: false
  },
  {
    id: 59,
    name: '填充物',
    checked: false
  },
  {
    id: 60,
    name: '创意材料类',
    checked: false
  },
  {
    id: 61,
    name: '装饰五金',
    checked: false
  },
  {
    id: 62,
    name: '功能五金',
    checked: false
  },
  {
    id: 63,
    name: '配套家具类',
    checked: false
  },
  {
    id: 65,
    name: '装饰材料',
    checked: false
  },
  {
    id: 83,
    name: '胶黏剂',
    checked: false
  },
  {
    id: 84,
    name: '封边条',
    checked: false
  },
  {
    id: 85,
    name: '涂料',
    checked: false
  },
  {
    id: 86,
    name: '其它',
    checked: false
  },
];
const options21 = [ // 国际品牌 - 产品类型
  {
    id: 1,
    name: '当代家具',
    checked: false
  },
  {
    id: 2,
    name: '古典家具',
    checked: false
  },
  {
    id: 3,
    name: '艺术家具',
    checked: false
  },
  {
    id: 4,
    name: '设计家具',
    checked: false
  },
  {
    id: 5,
    name: '灯具',
    checked: false
  },
  {
    id: 6,
    name: '创新材料',
    checked: false
  },
  {
    id: 7,
    name: '橱柜',
    checked: false
  },
  {
    id: 8,
    name: '卫浴',
    checked: false
  },
  {
    id: 9,
    name: '电器',
    checked: false
  },
  {
    id: 10,
    name: '生活用品',
    checked: false
  },
  {
    id: 11,
    name: '装饰品',
    checked: false
  },
  {
    id: 12,
    name: '艺术品',
    checked: false
  },
  {
    id: 13,
    name: '其它',
    checked: false
  }
];

const options9 = [ // 采购目的(设计材料)
  {
    id: 66,
    name: '专卖店经营',
    checked: false
  },
  {
    id: 67,
    name: '配套销售',
    checked: false
  },
  {
    id: 68,
    name: '项目采购',
    checked: false
  },
  {
    id: 69,
    name: '设计需求',
    checked: false
  },
  {
    id: 70,
    name: '其它',
    checked: false
  }
];
const options2 = [ // 采购目的(非设计材料)
  {
    id: 11,
    name: '专卖店经营',
    checked: false,
    children: [{
        id: 15,
        name: '所在卖场',
        ipt: ''
      },
      {
        id: 16,
        name: '目前经营品牌',
        ipt: ''
      },
      {
        id: 17,
        name: '新店投资预算',
        ipt: ''
      },
    ]
  },
  {
    id: 12,
    name: '配套销售',
    checked: false,
    children: [{
      id: 18,
      name: '目前经营品牌',
      ipt: ''
    }]
  },
  {
    id: 13,
    name: '项目采买',
    checked: false,
    children: [{
        id: 19,
        name: '私宅'
      },
      {
        id: 20,
        name: '商业项目'
      },
    ]
  },
  {
    id: 14,
    name: '其它',
    checked: false
  },
];

const options10 = [ // 产品定位(设计材料)
  {
    id: 71,
    name: '高端',
    checked: false
  },
  {
    id: 72,
    name: '中高端',
    checked: false
  },
  {
    id: 73,
    name: '高性价比',
    checked: false
  }
];
const options3 = [ // 产品定位(非设计材料)
  {
    id: 21,
    name: '高端',
    checked: false
  },
  {
    id: 22,
    name: '中高端',
    checked: false
  },
  {
    id: 23,
    name: '中端',
    checked: false
  },
  {
    id: 24,
    name: '高性价比',
    checked: false
  }
];

const options7 = [ // 行业属性(非设计材料、非国际品牌)
  {
    id: 50,
    name: '经销商',
    checked: false
  },
  {
    id: 51,
    name: '设计师',
    checked: false
  },
  {
    id: 52,
    name: '地产商',
    checked: false
  },
  {
    id: 53,
    name: '卖场/商场',
    checked: false
  },
  {
    id: 54,
    name: '电商',
    checked: false
  },
  {
    id: 55,
    name: '其它',
    checked: false
  }
];
const options11 = [ // 设计材料 - 行业属性
  {
    id: 74,
    name: '经销商',
    checked: false
  },
  {
    id: 75,
    name: '设计师',
    checked: false
  },
  {
    id: 76,
    name: '材料采购',
    checked: false
  },
  {
    id: 77,
    name: '家具采购',
    checked: false
  },
  {
    id: 78,
    name: '地产商',
    checked: false
  },
  {
    id: 79,
    name: '卖场/商场',
    checked: false
  },
  {
    id: 80,
    name: '电商',
    checked: false
  },
  {
    id: 81,
    name: '个人采购',
    checked: false
  },
  {
    id: 82,
    name: '其它',
    checked: false
  }
];
const options20 = [ // 国际品牌 - 行业属性
  {
    id: 1,
    name: '代理商',
    checked: false
  },
  {
    id: 2,
    name: '经销商',
    checked: false
  },
  {
    id: 3,
    name: '室内设计师/建筑师',
    checked: false
  },
  {
    id: 4,
    name: '产品设计师',
    checked: false
  },
  {
    id: 5,
    name: '材料采购',
    checked: false
  },
  {
    id: 6,
    name: '家具采购',
    checked: false
  },
  {
    id: 7,
    name: '地产商',
    checked: false
  },
  {
    id: 8,
    name: '卖场/商场',
    checked: false
  },
  {
    id: 9,
    name: '电商',
    checked: false
  },
  {
    id: 10,
    name: '个人采购',
    checked: false
  },
  {
    id: 11,
    name: '其它',
    checked: false
  }
];

const options22 = [ // 国际品牌 - 合作模式
  {
    id: 1,
    name: '独立店代理经营',
    checked: false
  },
  {
    id: 2,
    name: '零售商合作（集合品牌店）',
    checked: false
  },
  {
    id: 3,
    name: '项目采购合作',
    checked: false
  },
  {
    id: 4,
    name: '设计需求配合',
    checked: false
  },
  {
    id: 5,
    name: '渠道采购需求',
    checked: false
  },
  {
    id: 6,
    name: '供应商端扩充',
    checked: false
  },
  {
    id: 7,
    name: '其它',
    checked: false
  },
];
const options23 = [ // 国际品牌 - 资料类型
  {
    id: 1,
    name: '设计万象展会简介',
    checked: false
  },
  {
    id: 2,
    name: '区域经销商信息',
    checked: false
  },
  {
    id: 3,
    name: '品牌图册',
    checked: false
  },
  {
    id: 4,
    name: '品牌方或者总代理信息 ',
    checked: false
  },
  {
    id: 5,
    name: '其它',
    checked: false
  },
];

const options24 = [
  {
    id: 1,
    name: '线上推荐, 获取品牌资料及联系方式等',
    checked: false
  },
  {
    id: 2,
    name: '线下配对/线下探厂',
    checked: false
  },
  {
    id: 3,
    name: '其它',
    checked: false
  },
];

export default {
  options1,
  options4,
  options5,
  options6,
  options8,
  options21,
  options9,
  options2,
  options10,
  options3,
  options7,
  options11,
  options20,
  options22,
  options23,
  options24,
}
