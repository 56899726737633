<template>
  <div class="box">
    <div style="padding-right:.4rem;padding-left:.2rem;width:100%;">
      <p class="title1">一、产品需求（*为必填项）</p>
      <div>
        <!-- <p class="smallTitle">1、请选择您想寻找的产品类型*<br /><br />（最多可选三项）：</p> -->
        <p class="smallTitle">1、请选择您想寻找的产品类型 (最多可选三项) *:</p>
        <div class="firstBox">
          <van-checkbox-group v-model="result1" checked-color="#048DAD" icon-size="16" :max="3" class="checkGroup"
            @change="changeColor1" v-if="produceType == '精品家具'">
            <van-checkbox v-for="e in options1" :key="e.id" :name="e.name" class="checkOption"
              :class="e.checked ? 'color' : ''"><span>{{e.name}}</span></van-checkbox>
            <van-field v-model="value1" center placeholder="请输入信息" maxlength="50" class="field" v-if="showField1" />
          </van-checkbox-group>
          <van-checkbox-group v-model="result1" checked-color="#048DAD" icon-size="16" :max="3" class="checkGroup"
            @change="changeColor1" v-if="produceType == '软装饰品'">
            <van-checkbox v-for="e in options4" :key="e.id" :name="e.name" class="checkOption"
              :class="e.checked ? 'color' : ''"><span>{{e.name}}</span></van-checkbox>
            <van-field v-model="value1" center placeholder="请输入信息" maxlength="50" class="field" v-if="showField1" />
          </van-checkbox-group>
          <van-checkbox-group v-model="result1" checked-color="#048DAD" icon-size="16" :max="3" class="checkGroup"
            @change="changeColor1" v-if="produceType == '建材卫浴'">
            <van-checkbox v-for="e in options5" :key="e.id" :name="e.name" class="checkOption"
              :class="e.checked ? 'color' : ''"><span>{{e.name}}</span></van-checkbox>
            <van-field v-model="value1" center placeholder="请输入信息" maxlength="50" class="field" v-if="showField1" />
          </van-checkbox-group>
          <van-checkbox-group v-model="result1" checked-color="#048DAD" icon-size="16" :max="3" class="checkGroup"
            @change="changeColor1" v-if="produceType == '时尚灯饰'">
            <van-checkbox v-for="e in options6" :key="e.id" :name="e.name" class="checkOption"
              :class="e.checked ? 'color' : ''"><span>{{e.name}}</span></van-checkbox>
            <van-field v-model="value1" center placeholder="请输入信息" maxlength="50" class="field" v-if="showField1" />
          </van-checkbox-group>
          <van-checkbox-group v-model="result1" checked-color="#048DAD" icon-size="16" :max="3" class="checkGroup"
            @change="changeColor1" v-if="produceType == '设计材料'">
            <van-checkbox v-for="e in options8" :key="e.id" :name="e.name" class="checkOption"
              :class="e.checked ? 'color' : ''"><span>{{e.name}}</span></van-checkbox>
            <van-field v-model="value1" center placeholder="请输入信息" maxlength="50" class="field" v-if="showField1" />
          </van-checkbox-group>
          <van-checkbox-group v-model="result1" checked-color="#048DAD" icon-size="16" :max="3" class="checkGroup"
            @change="changeColor1" v-if="produceType == '国际品牌'">
            <van-checkbox v-for="e in options21" :key="e.id" :name="e.name" class="checkOption"
              :class="e.checked ? 'color' : ''"><span>{{e.name}}</span></van-checkbox>
            <van-field v-model="value1" center placeholder="请输入信息" maxlength="50" class="field" v-if="showField1" />
          </van-checkbox-group>
        </div>
      </div>
      
      <template v-if="produceType == '国际品牌'">
        <p class="smallTitle">2、您想合作的模式 (可多选) *:</p>
        <div class="firstBox">
          <van-checkbox-group v-model="result22" checked-color="#048DAD" icon-size="16" class="checkGroup"
            @change="changeColor22">
            <van-checkbox v-for="e in options22" :key="e.id" :name="e.name" class="checkOption"
              :class="e.checked ? 'color' : ''"><span>{{e.name}}</span></van-checkbox>
            <van-field v-model="value22" center placeholder="请输入信息" maxlength="50" class="field" v-if="showField2" />
          </van-checkbox-group>
        </div>
        <p class="smallTitle">3、您需要什么类型的资料*:</p>
        <div class="firstBox">
          <van-radio-group v-model="radio23" checked-color="#048DAD" icon-size="16" class="radioGroup"
            style="padding-bottom:0;" @change="changeColor23">
            <van-radio :name="item.name" v-for="(item,index) in options23" class="checkOption" :key="index">
              <span :class="item.checked ? 'radioColor' : ''">{{item.name}}</span>
            </van-radio>
            <van-field v-model="value23" center placeholder="请输入信息" maxlength="50" class="field" v-if="showField3" />
          </van-radio-group>
        </div>
      </template>
      <template v-else>
        <p class="smallTitle">2、您采购产品的目的*：</p>
        <div class="firstBox">
          <van-radio-group v-model="radio" class="radioGroup" checked-color="#048DAD" icon-size="16"
            @change="changeColor2" style="padding: .1rem 0 0 .1rem;" v-if="produceType != '设计材料'">
            <van-radio :name="b.name" v-for="(b,index) in options2" :key="index" class="checkOption"><span
                :class="b.checked ? 'radioColor' : ''">{{b.name}}</span>
              <template v-if="b.checked">
                <div v-if="b.id == 11 || b.id == 12">
                  <div v-for="(c,index) in b.children" :key="index" class="childDiv">
                    <span>{{c.name}}</span>
                    <van-field v-model="c.ipt" center placeholder="请输入信息" maxlength="50" class="marketField"
                      style="margin-left:0;" />
                  </div>
                </div>
                <van-radio-group v-model="radio1" checked-color="#048DAD" icon-size="16" v-if="b.id == 13">
                  <van-radio :name="d.name" v-for="(d,index) in b.children" class="checkOption" :key="index">{{d.name}}
                  </van-radio>
                </van-radio-group>
              </template>
            </van-radio>
            <van-field v-model="value2" center placeholder="请输入信息" maxlength="50" class="field" v-if="showField2" />
          </van-radio-group>
          <van-radio-group v-model="radio" class="radioGroup" checked-color="#048DAD" icon-size="16"
            @change="changeColor2" style="padding: .1rem 0 0 .1rem;" v-if="produceType == '设计材料'">
            <van-radio :name="b.name" v-for="(b,index) in options9" :key="index" class="checkOption"><span
                :class="b.checked ? 'radioColor' : ''">{{b.name}}</span></van-radio>
            <van-field v-model="value2" center placeholder="请输入信息" maxlength="50" class="field" v-if="showField2" />
          </van-radio-group>
        </div>
      </template>
      
      <!-- 
      <template v-if="produceType == '精品家具'">
        <p class="smallTitle">3、您是否对产品材质有要求*？</p>
        <van-radio-group v-model="radio2" checked-color="#048DAD" icon-size="16" class="radioGroup"
          @change="changeColor5">
          <div class="otherChild">
            <van-radio name="是" class="bottomOption"><span :class="radio2 == '是' ? 'radioColor' : ''">是</span>
            </van-radio>
            <van-field v-model="value3" center placeholder="请输入信息" maxlength="50" class="field"
              v-if="radio2 && radio2=='是'" />
          </div>
          <van-radio name="否" class="bottomOption"><span :class="radio2 == '否' ? 'radioColor' : ''">否</span></van-radio>
        </van-radio-group>
        <p class="smallTitle">4、您是否对产品风格有要求*？</p>
        <van-radio-group v-model="radio3" checked-color="#048DAD" icon-size="16" class="radioGroup"
          @change="changeColor6">
          <div class="otherChild">
            <van-radio name="是" class="bottomOption"><span :class="radio3 == '是' ? 'radioColor' : ''">是</span>
            </van-radio>
            <van-field v-model="value4" center placeholder="请输入信息" maxlength="50" class="field"
              v-if="radio3 && radio3=='是'" />
          </div>
          <van-radio name="否" class="bottomOption"><span :class="radio3 == '否' ? 'radioColor' : ''">否</span></van-radio>
        </van-radio-group>
      </template>
      -->
      
      <div v-if="produceType == '精品家具' || produceType == '时尚灯饰' || produceType == '设计材料'">
        <p class="smallTitle">
          <span v-if="produceType == '时尚灯饰' || produceType == '设计材料'">3、</span>
          <span v-if="produceType == '精品家具'">3、</span>您对产品的定位要求*：
        </p>
        <div class="firstBox">
          <van-radio-group v-model="radio4" checked-color="#048DAD" icon-size="16" class="radioGroup"
            style="padding-bottom:0;" @change="changeColor3" v-if="produceType != '设计材料'">
            <van-radio :name="item.name" v-for="(item,index) in options3" class="checkOption" :key="index"><span
                :class="item.checked ? 'radioColor' : ''">{{item.name}}</span></van-radio>
          </van-radio-group>
          <van-radio-group v-model="radio4" checked-color="#048DAD" icon-size="16" class="radioGroup"
            style="padding-bottom:0;" @change="changeColor3" v-if="produceType == '设计材料'">
            <van-radio :name="item.name" v-for="(item,index) in options10" class="checkOption" :key="index"><span
                :class="item.checked ? 'radioColor' : ''">{{item.name}}</span></van-radio>
          </van-radio-group>
        </div>
      </div>
      <p class="smallTitle">
        <span v-if="produceType == '精品家具' || produceType == '时尚灯饰' || produceType == '设计材料' || produceType == '国际品牌'">4、</span>
        <span v-if="produceType == '软装饰品' || produceType == '建材卫浴'">3、</span>
        <span>您希望以何种方式与品牌对接*：</span>
      </p>
      <div class="firstBox">
        <van-checkbox-group v-model="result24" checked-color="#048DAD" icon-size="16" class="checkGroup" @change="changeColor24">
          <van-checkbox v-for="e in options24" :key="e.id" :name="e.name" class="checkOption" :class="e.checked ? 'color' : ''"><span>{{e.name}}</span></van-checkbox>
          <van-field v-model="value24" center placeholder="请输入信息" maxlength="50" class="field" v-if="showField24" />
        </van-checkbox-group>
      </div>
      
      <p class="title1">二、基本信息（*为必填项）</p>
      <div class="nameBox center">
        <span class="text">姓名*：</span>
        <van-field v-model="name" center placeholder="请输入信息" maxlength="50" class="field" />
      </div>
      <div class="nameBox">
        <span class="text">行业属性*：</span>
        <van-radio-group v-model="industryVal" checked-color="#048DAD" icon-size="16" :max="3" class="industryBox"
          @change="changeColor4" v-if="produceType != '设计材料' && produceType != '国际品牌'">
          <van-radio v-for="f in options7" :key="f.id" :name="f.name" class="industryOption"><span
              :class="f.checked?'radioColor':''">{{f.name}}</span></van-radio>
          <van-field v-model="value5" center placeholder="请输入信息" maxlength="50" class="otherField"
            v-if="!industryDisabled" />
            <!-- :disabled="industryDisabled" -->
        </van-radio-group>
        <van-radio-group v-model="industryVal" checked-color="#048DAD" icon-size="16" :max="3" class="industryBox"
          @change="changeColor4" v-if="produceType == '设计材料'">
          <van-radio v-for="f in options11" :key="f.id" :name="f.name" class="industryOption"><span
              :class="f.checked?'radioColor':''">{{f.name}}</span></van-radio>
          <van-field v-model="value5" center placeholder="请输入信息" maxlength="50" class="otherField"
            v-if="!industryDisabled" />
        </van-radio-group>
        <van-radio-group v-model="industryVal" checked-color="#048DAD" icon-size="16" :max="3" class="industryBox"
          @change="changeColor4" v-if="produceType == '国际品牌'">
          <van-radio v-for="f in options20" :key="f.id" :name="f.name" class="industryOption"><span
              :class="f.checked?'radioColor':''">{{f.name}}</span></van-radio>
          <van-field v-model="value5" center placeholder="请输入信息" maxlength="50" class="otherField industryOption-other"
            v-if="!industryDisabled" />
        </van-radio-group>
      </div>
      <div class="nameBox center">
        <span class="text">公司名称*：</span>
        <van-field v-model="company" center placeholder="请输入信息" maxlength="50" class="field" />
      </div>
      <div class="nameBox center">
        <span class="text">职务*：</span>
        <van-field v-model="position" center placeholder="请输入信息" maxlength="50" class="field" />
      </div>
      <div class="nameBox center">
        <span class="text">联系电话*：</span>
        <van-field v-model="phone" center placeholder="请输入信息" maxlength="11" class="field" />
      </div>
      <div class="nameBox center">
        <span class="text">公司地址*：</span>
        <van-field v-model="area" center readonly placeholder="请选择地区" class="field" @click="chooseArea" />
      </div>
      <van-field v-model="address" center placeholder="请输入详细信息" maxlength="50" class="field lastField" />
    
    </div>
    
    <van-button type="info" size="normal" color="#048DAD" class="btn" @click="submitMessage">提交</van-button>
    <!-- 地区弹出层 -->
    <van-popup v-model="showAreaPopup" position="bottom" :style="{ height: '50%' }">
      <van-area title="地区选择" :area-list="areaList" :columns-num="2" @cancel="showAreaPopup = false"
        @confirm="confirmArea" @change="changeArea" style="color:#000;" />
    </van-popup>
  
  </div>
</template>
<script>
  import areaList from '../../api/area';
  import produceTypeList from './produce-type.js';
  import { Toast } from 'vant';
  import { submitInfo, queryInfo } from '@/api/accentFurnitures.js';
  export default {
    data() {
      return {
        produceType: '',
        result1: [], // 产品类型选中值
        result24: [], // 品牌对接方式选中值
        options1: produceTypeList.options1,  // 精品家具 - 产品类型
        options4: produceTypeList.options4, // 软装饰品 - 产品类型
        options5: produceTypeList.options5, // 建材卫浴 - 产品类型
        options6: produceTypeList.options6, // 时尚灯饰 - 产品类型
        options8: produceTypeList.options8, // 设计材料 - 产品类型
        options21: produceTypeList.options21, // 国际品牌 - 产品类型
        
        options9: produceTypeList.options9, // 采购目的(设计材料)
        options2: produceTypeList.options2, // 采购目的(非设计材料)
        options10: produceTypeList.options10, // 产品定位(设计材料)
        options3: produceTypeList.options3, // 产品定位(非设计材料)
        options7: produceTypeList.options7, // 行业属性(非设计材料、非国际品牌)
        options11: produceTypeList.options11, // 设计材料 - 行业属性
        options20: produceTypeList.options20, // 国际品牌 - 行业属性
        options22: produceTypeList.options22, // 国际品牌 - 合作模式
        options23: produceTypeList.options23, // 国际品牌 - 资料类型
        options24: produceTypeList.options24, // 品牌对接方式
        
        result22: [], // 国际品牌 - 合作模式 - 选中值
        value22: '', // 国际品牌 - 合作模式 - 其它的值
        radio23: '', // 国际品牌 - 资料类型 - 选中的值
        value23: '', // 国际品牌 - 资料类型 - 其它的值
        
        value1: '', //产品类型点击其它的值
        value2: '', //采购目的点击其它的值
        value3: '', //产品材质要求点击是的时候填写的值
        value4: '', //产品风格要求点击是的时候填写的值
        value5: '', //行业属性 点击其它的时候填写的值
        value24: '', // 品牌对接方式 点击其它的时候填写的值
        
        radio: '', //采购目的值
        radio1: '', //采购目的点击项目采买的值
        radio2: '', //产品材质值
        radio3: '', //产品风格值
        radio4: '', //产品定位要求值
        name: '', //姓名
        industryVal: '', //行业属性
        company: '', //公司
        position: '', //职位
        phone: '', //手机号
        area: '', //地区
        address: '', //地址
        showAreaPopup: false, //是否显示省市弹出层
        areaList: areaList,
        provinceName: '', //省
        provinceId: null,
        cityName: '', //市
        cityId: null,
        industryDisabled: true, //行业选择选择其它禁用状态
        showField1: false, //是否显示输入框
        showField2: false, //是否显示输入框
        showField3: false, //是否显示输入框
        showField24: false, //是否显示输入框 - 品牌对接方式
      }
    },
    created() {
      this.produceType = this.$route.query.produceType || '';
      this.produceType && (document.title = this.produceType);
      this.getInfo();
    },
    methods: {
      // 产品类型选择事件
      changeColor1() {
        if (this.produceType == '精品家具') {
          this.options1.map(e => {
            e.checked = this.result1.includes(e.name);
          })
          if (this.result1.includes('其它')) {
            this.showField1 = true
          } else {
            this.showField1 = false
            this.value1 = ''
          }
        } else if (this.produceType == '软装饰品') {
          this.options4.map(e => {
            e.checked = this.result1.includes(e.name);
          })
          if (this.result1.includes('其它')) {
            this.showField1 = true
          } else {
            this.showField1 = false
            this.value1 = ''
          }
        } else if (this.produceType == '建材卫浴') {
          this.options5.map(e => {
            e.checked = this.result1.includes(e.name);
          })
          if (this.result1.includes('其它')) {
            this.showField1 = true
          } else {
            this.showField1 = false
            this.value1 = ''
          }
        } else if (this.produceType == '设计材料') {
          this.options8.map(e => {
            e.checked = this.result1.includes(e.name);
          })
          if (this.result1.includes('其它')) {
            this.showField1 = true
          } else {
            this.showField1 = false
            this.value1 = ''
          }
        } else {
          this.options6.map(e => {
            e.checked = this.result1.includes(e.name);
          })
          if (this.result1.includes('其它')) {
            this.showField1 = true
          } else {
            this.showField1 = false
            this.value1 = ''
          }
        }
      },
      // 品牌对接方式选择事件
      changeColor24() {
        this.options24.map(e => {
          e.checked = this.result24.includes(e.name);
        })
        if (this.result24.includes('其它')) {
          this.showField24 = true
        } else {
          this.showField24 = false
          this.value24 = ''
        }
      },
      // 合作模式选择事件
      changeColor22() {
        this.options22.map(e => {
          e.checked = this.result22.includes(e.name);
        })
        if (this.result22.includes('其它')) {
          this.showField2 = true
        } else {
          this.showField2 = false
          this.value22 = ''
        }
      },
      // 合作模式选择事件
      changeColor23() {
        this.options23.map(e => {
          e.checked = this.radio23 == e.name;
        })
        if (this.radio23 == '其它') {
          this.showField3 = true
        } else {
          this.showField3 = false
          this.value23 = ''
        }
      },
      // 产品采购目的选择事件
      changeColor2() {
        if (this.produceType == '设计材料') {
          this.options9.map(e => {
            e.checked = this.radio == e.name
          })
        } else {
          this.options2.map(e => {
            e.checked = this.radio == e.name
          })
        }
        if (this.radio == '专卖店经营') {
          this.options2[1].children.map(e => {
            e.ipt = ''
          })
          this.radio1 = ''
        }
        if (this.radio == '配套销售') {
          this.options2[0].children.map(e => {
            e.ipt = ''
          })
          this.radio1 = ''
        }
        if (this.radio == '项目采买') {
          this.options2[1].children.map(e => {
            e.ipt = ''
          })
          this.options2[0].children.map(e => {
            e.ipt = ''
          })
          // this.radio1 = ''
        }
        if (this.radio == '其它') {
          this.showField2 = true
          this.radio1 = ''
        } else {
          this.showField2 = false
          this.value2 = ''
          // this.radio1 = ''
        }
      },
      // 产品定位要求选择事件
      changeColor3() {
        if (this.produceType == '设计材料') {
          this.options10.map(e => {
            e.checked = this.radio4 == e.name
          })
        } else {
          this.options3.map(e => {
            e.checked = this.radio4 == e.name
          })
        }
      },
      // 行业属性选择事件
      changeColor4() {
        if (this.produceType == '设计材料') {
          this.options11.map(e => {
            e.checked = this.industryVal == e.name
          })
        } else {
          this.options7.map(e => {
            e.checked = this.industryVal == e.name
          })
        }
        if (this.industryVal == '其它') {
          this.industryDisabled = false
        } else {
          this.industryDisabled = true
          this.value5 = ''
        }
      },
      // 产品材质要求
      changeColor5() {
        if (this.radio2 == "否") {
          this.value3 = ''
        }
      },
      // 产品风格要求
      changeColor6() {
        if (this.radio3 == "否") {
          this.value4 = ''
        }
      },
      chooseArea() {
        this.showAreaPopup = true
      },
      confirmArea(val) {
        this.area = val[0].name + " " + val[1].name;
        this.provinceName = val[0].name
        this.provinceId = val[0].code
        this.cityName = val[1].name
        this.cityId = val[1].code
        this.showAreaPopup = false
      },
      changeArea() {
        if (!this.area) {
          this.area = ''
        }
      },
      getInfo() {
        let unionid = localStorage.getItem('unionid')
        queryInfo(unionid).then(res => {
          if (res.data) {
            this.name = res.data.name
            this.industryVal = res.data.trade
            this.company = res.data.company
            this.position = res.data.position
            this.phone = res.data.phone
            this.address = res.data.companyAdd
            this.area = res.data.provinceName && res.data.cityName ? res.data.provinceName + ' ' + res.data
              .cityName : ''
          }
        })
      },
      valid() {
        if (this.result1.length == 0) {
          Toast('产品类型未选择')
          return false;
        }
        if (this.result1.includes('其它') && !this.value1) {
          Toast('产品类型选择了 “其它” ，请输入其它产品类型')
          return false;
        }
        if (this.produceType == '国际品牌') {
          if (this.result22.length == 0) {
            Toast('请选择合作模式')
            return false;
          }
          if (this.result22.includes('其它') && !this.value22) {
            Toast('合作模式选择了 “其它” ，请输入其它合作模式')
            return false;
          }
          if (!this.radio23) {
            Toast('请选择资料类型')
            return false;
          }
          if (this.radio23 == '其它' && !this.value23) {
            Toast('资料类型选择了 “其它” ，请输入其它资料类型')
            return false;
          }
        } else {
          
          if (!this.radio) {
            Toast('采购产品目的未选择')
            return false;
          }
          if (this.radio == '其它' && !this.value2) {
            Toast('采购目的选择了 “其它” ，请输入其它采购目的')
            return false;
          }
        }
        
        if (this.produceType != '设计材料') {
          if (this.radio == '专卖店经营' && !this.options2[0].children[0].ipt) {
            Toast('采购目的-专卖店经营-所在卖场 未填写')
            return false;
          }
          if (this.radio == '专卖店经营' && !this.options2[0].children[1].ipt) {
            Toast('采购目的-专卖店经营-目前经营品牌 未填写')
            return false;
          }
          if (this.radio == '专卖店经营' && !this.options2[0].children[2].ipt) {
            Toast('采购目的-专卖店经营-新店投资预算 未填写')
            return false;
          }
          if (this.radio == '配套销售' && !this.options2[1].children[0].ipt) {
            Toast('采购目的-配套销售-目前经营品牌 未填写')
            return false;
          }
          if (this.radio == '项目采买' && !this.radio1) {
            Toast('采购目的-项目采买 未选择')
            return false;
          }
        }
        
        if (this.produceType == '精品家具') {
          // if (!this.radio2) {
          //   Toast('产品材质要求未选择')
          //   return false;
          // }
          // if (!this.radio3) {
          //   Toast('产品风格要求未选择')
          //   return false;
          // }
          if (!this.radio4) {
            Toast('产品定位要求未选择')
            return false;
          }
        }
        if (this.produceType == '时尚灯饰' || this.produceType == '设计材料') {
          if (!this.radio4) {
            Toast('产品定位要求未选择')
            return false;
          }
        }
        
        if (this.result24.length == 0) {
          Toast('品牌对接方式未选择')
          return false;
        }
        if (this.result24.includes('其它') && !this.value24) {
          Toast('品牌对接方式选择了 “其它” ，请输入其它对接方式')
          return false;
        }
        
        if (!this.name) {
          Toast('姓名未输入');
          return false;
        }
        if (!this.industryVal) {
          Toast('行业属性未选择');
          return false;
        }
        if (this.industryVal == '其它' && !this.value5) {
          Toast('行业属性选择了 “其它” ，请输入其它行业属性');
          return false;
        }
        if (!this.company) {
          Toast('公司名称未输入');
          return false;
        }
        if (!this.position) {
          Toast('职务未输入');
          return false;
        }
        if (!this.phone) {
          Toast('联系电话未输入');
          return false;
        }
        if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.phone)) {
          Toast('手机号格式填写错误');
          return false;
        }
        if (!this.area) {
          Toast('公司地址未选择');
          return false;
        }
        if (!this.address) {
          Toast('公司地址详细信息未输入');
          return false;
        }
        return true
      },
      submitMessage() {
        if (this.valid()) {
          let source = localStorage.getItem('source');
          let unionid = localStorage.getItem('unionid');
          let params = {
            data_sources: source, //数据来源,
            unionid: unionid, //unionid,
            intended_product: this.produceType, //意向产品,精品家具,建材卫浴,时尚灯饰
            product_type: this.result1.join(','), //产品类型，多选，逗号分隔
            product_type_other: this.value1, //产品类型-其它
            
            cooperation_mode: this.result22.join(','), // “国际品牌” - 合作模式 - 选中值
            cooperation_mode_other: this.value22,      // “国际品牌” - 合作模式 - 其它的值
            kind_of_need_material: this.radio23,       // “国际品牌” - 资料类型 - 选中的值
            kind_of_need_material_other: this.value23, // “国际品牌” - 资料类型 - 其它的值
            
            procurement_purpose: this.radio, //采购目的（单选）填写  专卖店经营/配套销售(散货)/项目采买/其它
            procurement_purpose_shop_store: this.options2[0].children[0].ipt, //采购目的,专卖店经营-所在卖场 
            procurement_purpose_shop_brand: this.options2[0].children[1].ipt, //采购目的,专卖店经营-品牌
            procurement_purpose_shop_budget: this.options2[0].children[2].ipt, //采购目的,专卖店经营-品牌
            procurement_purpose_sales: this.options2[1].children[0].ipt, //采购目的-配套销售(散货)-品牌
            procurement_purpose_project: this.radio1, //采购目的-私宅 或 商业项目
            procurement_purpose_other: this.value2, //采购目的-其它-具体内容
            
            product_material: this.radio2, //产品材质要求--是或否
            product_material_detail: this.value3, //产品材质要求-详细填写内容
            product_style: this.radio3, //产品风格,--是或否
            product_style_detail: this.value4, //产品风格-详细填写内容
            product_positioning: this.radio4, //产品定位
            
            brank_contacts_type: this.result24.join(','), // 品牌对接方式: 多选，逗号分隔
            brank_contacts_remark: this.value24, // 品牌对接方式-其它
            
            client_name: this.name, //姓名,
            trade: this.industryVal, // 行业属性
            trade_other: this.value5, // 行业属性其它值
            company: this.company, //公司名称：,
            position: this.position, //职务,
            phone: this.phone, //联系电话,
            provinceId: this.provinceId, //省编号,
            cityId: this.cityId, //城市编号,
            provinceName: this.provinceName, //省,
            cityName: this.cityName, //城市,
            detail_address: this.address, //详细地址,
          };
          submitInfo(params).then(res => {
            if (res.code == 200) {
              this.$router.replace({
                path: '/otherResult'
              })
            } else {
              Toast(res.message)
            }
          })
        }

      }
    },
  }
</script>
<style lang="less" scoped>
  @color: #048DAD;

  .color /deep/ .van-checkbox__label span {
    color: @color
  }

  .box {
    background-color: #EDEDED;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
  }

  .title1 {
    color: @color;
    font-size: .16rem;
    margin: .2rem 0;
  }

  .smallTitle {
    color: @color;
    font-size: .14rem;
  }

  .firstBox {
    width: 100%;
    margin-bottom: .3rem;
    font-size: .14rem;
  }

  .checkGroup {
    padding: .1rem 0 0 .1rem;
  }

  .checkOption {
    margin: .1rem 0 .1rem 0;
    display: flex;
    align-items: flex-start;
  }

  .industryOption {
    margin: 0 0 .1rem 0;
    // display: flex;
    // align-items: flex-start;
  }
  .industryOption-other {
    padding: 5px 10px;
    height: .3rem !important;
  }

  .radioColor {
    color: @color;
  }

  .field {
    // width: 60%;
    width: 69%;
    height: .36rem;
    border-radius: .05rem;
    margin-left: .1rem;
  }

  .marketField {
    width: 60%;
    height: .36rem;
    border-radius: .05rem;
  }

  .radioGroup {
    width: 100%;
    padding: .1rem 0 .1rem .1rem;
    font-size: .14rem;
  }

  .childDiv {
    width: 100%;
    margin-top: .1rem;
    font-size: .14rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .otherChild {
    width: 100%;
    margin-top: .1rem;
    font-size: .14rem;
  }

  .bottomOption {
    margin: .1rem 0 .1rem 0;
    font-size: .14rem;
  }

  .nameBox {
    display: flex;
    justify-content: flex-end;
    margin-bottom: .2rem;
    font-size: .14rem;
  }

  .center {
    align-items: center;
  }

  .text {
    display: block;
    width: .8rem;
    color: #000;
    text-align: right;
  }

  .industryBox {
    padding: 0 0 0 .1rem;
    // width: 66.5%;
    width: 72%;
    margin-bottom: .2rem;
    position: relative;
  }

  .otherField {
    width: 60%;
    height: .36rem;
    border-radius: .05rem;
    position: absolute;
    bottom: .03rem;
    left: 40%;
  }

  .lastField {
    margin-bottom: .3rem;
    float: right;
  }

  .btn {
    font-size: .16rem;
    height: .4rem;
    border-radius: .05rem;
    width: 50%;
    margin: .2rem auto .4rem;
    display: block;
  }
</style>
